///////////////////////////////////////////////////////////
// File        : NavbarItem.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./NavbarItem.scss";
import NavbarDropdown from "~/Components/Layouts/NavbarDropdown/NavbarDropdown";
import Link from "~/Components/Elements/Link/Link";

// Function Definition
function NavbarItem(props) {
  // console.info("NavbarItem.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id, className, children} = props;
  const [item, setItem] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const sys = response.data.sys; // jsonpath.value(response, "$.data.sys"); // Get the metadata for this component
          // console.info(JSON.stringify(sys, null, 2));
          if (sys && isMounted.current) {
            switch (sys.contentType.sys.id) {
              case "layoutsNavbarDropdown":
                setItem(
                  <NavbarDropdown id={id} />
                );
                break;
              case "elementsLink":
                setItem(
                  <li className="nav-item mr-lg-4">
                    <Link id={id} className="nav-link" />
                  </li>
                );
                break;
              default:
                break;
            }
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <>
      {item}
    </>
  );
}

// Interface
NavbarItem.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
NavbarItem.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default NavbarItem;

