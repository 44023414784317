///////////////////////////////////////////////////////////
// File        : Header.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import { isLoggedIn } from "~/Components/api/google";
import "./Header.scss";
import Logo from "~/Components/Items/Logo/Logo";
import Link from "~/Components/Elements/Link/Link";
import Navbar from "~/Components/Layouts/Navbar/Navbar";

// Function Definition
function Header(props) {
  // console.info("Header.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [logo, setLogo] = useState("");
  const [home, setHome] = useState("");
  const [items, setItems] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.home && setHome(
              <Link id={fields.home.sys.id} className="navbar-brand">
                <Logo/>
              </Link>
            );
            if (fields.items && Array.isArray(fields.items)) {
              setItems(fields.items.map((item, index) => (
                <Navbar key={item.sys.id} id={item.sys.id} />
              )));
            }
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <div className="header">
      <div className="branding">
        <div className="container">
          <nav className="navbar navbar-expand-lg" >
            <div className="site-logo mr-5">
              {home}
            </div>
            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
              <span> </span>
              <span> </span>
              <span> </span>
            </button>
            <div className="collapse navbar-collapse pt-3 pt-lg-0" id="navigation">
              {items}
              {/*
              <ul className="navbar-nav">
                <li className="nav-item mr-lg-4">
                  <a className="nav-link" href="product.html">Product</a>
                </li>
                <li className="nav-item mr-lg-4">
                  <a className="nav-link" href="pricing.html">Pricing</a>
                </li>
                <li className="nav-item mr-lg-4">
                  <a className="nav-link" href="customers.html">Customers</a>
                </li>
                <li className="nav-item dropdown mr-lg-4">
                  <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    About Us
                  </a>
                  <div className="dropdown-menu dropdown-menu-right rounded shadow menu-animate slideIn" aria-labelledby="navbarDropdown">
                    <a className="dropdown-item has-icon" href="team.html">Team</a>
                    <a className="dropdown-item has-icon" href="case.html">Case Study</a>
                    <a className="dropdown-item has-icon" href="support.html">Support</a>
                    <a className="dropdown-item has-icon" href="blog.html">Blog</a>
                    <a className="dropdown-item has-icon" href="blog-post.html">Blog Post</a>
                    <a className="dropdown-item has-icon" href="contact.html">Contact</a>
                  </div>
                </li>
              </ul>
              */}
              <ul className="navbar-nav ml-auto">
                <li className="nav-item mr-lg-3">
                  {
                    isLoggedIn()
                    ?
                    <a className="nav-link mt-1 mb-3 mb-lg-0" href="shop">Shop</a>
                    :
                    <a className="nav-link mt-1 mb-3 mb-lg-0" href="signin">Sign In</a>
                  }
                </li>
                <li className="nav-item">
                  <a className="nav-btn btn btn-gradient text-white" href="signup">Free Trial</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
}

// Interface
Header.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Header.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Header;

