///////////////////////////////////////////////////////////
// File        : Footer.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Footer.scss";
import Logo from "~/Components/Items/Logo/Logo";
import Link from "~/Components/Elements/Link/Link";

// Function Definition
function Footer(props) {
  // console.info("Footer.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [logo, setLogo] = useState("");
  const [description, setDescription] = useState("");
  const [demo, setDemo] = useState("");
  const [pricing, setPricing] = useState("");
  const [questions, setQuestions] = useState("");
  const [support, setSupport] = useState("");
  const [stories, setStories] = useState("");
  const [blog, setBlog] = useState("");
  const [webinars, setWebinars] = useState("");
  const [press, setPress] = useState("");
  const [team, setTeam] = useState("");
  const [career, setCareer] = useState("");
  const [data, setData] = useState("");
  const [contact, setContact] = useState("");
  const [privacy, setPrivacy] = useState("");
  const [terms, setTerms] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.logo && setLogo(fields.logo);
            fields.description && setDescription(fields.description);
            fields.demo && setDemo(<Link id={fields.demo.sys.id} />);
            fields.pricing && setPricing(<Link id={fields.pricing.sys.id} />);
            fields.questions && setQuestions(<Link id={fields.questions.sys.id} />);
            fields.support && setSupport(<Link id={fields.support.sys.id} />);
            fields.stories && setStories(<Link id={fields.stories.sys.id} />);
            fields.blog && setBlog(<Link id={fields.blog.sys.id} />);
            fields.webinars && setWebinars(<Link id={fields.webinars.sys.id} />);
            fields.press && setPress(<Link id={fields.press.sys.id} />);
            fields.team && setTeam(<Link id={fields.team.sys.id} />);
            fields.career && setCareer(<Link id={fields.career.sys.id} />);
            fields.data && setData(<Link id={fields.data.sys.id} />);
            fields.contact && setContact(<Link id={fields.contact.sys.id} />);
            fields.privacy && setPrivacy(<Link id={fields.privacy.sys.id} />);
            fields.terms && setTerms(<Link id={fields.terms.sys.id} />);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <footer className="footer">
      <div className="container py-5 mb-3">
        <div className="row">
          <div className="footer-col col-12 col-md-6 col-lg-5 mb-3">
            <h4>
              <Logo/>
            </h4>
            <div>{description}</div>
            <ul className="social-list list-unstyled pt-4 mb-0">
              <li className="list-inline-item"><a href="#"><i className="fab fa-github fa-fw"></i></a></li> 
              <li className="list-inline-item"><a href="#"><i className="fab fa-twitter fa-fw"></i></a></li>
              <li className="list-inline-item"><a href="#"><i className="fab fa-facebook-f fa-fw"></i></a></li>
              <li className="list-inline-item"><a href="#"><i className="fab fa-instagram fa-fw"></i></a></li>
            </ul>
          </div>
          <div className="footer-col col-12 col-md-6  col-lg-7 mb-lg-3">
            <div className="row">
              <div className="footer-col-sub col-6 col-sm-4 col-lg-4 mb-4">
                <h4 className="col-heading">Product</h4>
                <ul className="list-unstyled mb-0">
                  <li>{demo}</li>
                  <li>{pricing}</li>
                  <li>{questions}</li>
                  <li>{support}</li>
                </ul>
              </div>
              <div className="footer-col-sub col-6 col-sm-4 col-lg-4 mb-4">
                <h4 className="col-heading">Resources</h4>
                <ul className="list-unstyled mb-0">
                  <li>{stories}</li>
                  <li>{blog}</li>
                  <li>{webinars}</li>
                  <li>{press}</li>
                </ul>
              </div>
              <div className="footer-col-sub col-6 col-sm-4 col-lg-4 mb-4">
                <h4 className="col-heading">About</h4>
                <ul className="list-unstyled mb-0">
                  <li>{team}</li>
                  <li>{career}</li>
                  <li>{data}</li>
                  <li>{contact}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr/>
        <div className="footer-bottom">
          <div className="row">
            <div className="copyright col-12 col-md-6">
              Copyright &copy; XL Direct Limited
            </div>
            <div className="legal col-12 col-md-6 text-md-right">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">{privacy}</li>
                <li className="list-inline-item">|</li>
                <li className="list-inline-item">{terms}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

// Interface
Footer.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Footer.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Footer;

