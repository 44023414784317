///////////////////////////////////////////////////////////
// File        : Navbar.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Navbar.scss";
import NavbarItem from "~/Components/Items/NavbarItem/NavbarItem";

// Function Definition
function Navbar(props) {
  // console.info("Navbar.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [items, setItems] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            if (fields.items && Array.isArray(fields.items)) {
              setItems(fields.items.map((item, index) => (
                <NavbarItem key={item.sys.id} id={item.sys.id} />
              )));
            }
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <ul className="navbar-nav">
      {items}
    </ul>
  );
}

// Interface
Navbar.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Navbar.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Navbar;

