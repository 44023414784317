///////////////////////////////////////////////////////////
// File        : NavbarDropdownItem.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./NavbarDropdownItem.scss";
import Link from "~/Components/Elements/Link/Link";

// Function Definition
function NavbarDropdownItem(props) {
  // console.info("NavbarDropdownItem.render");
  const {id} = props;
  return (
    <Link id={id} className="dropdown-item has-icon" />
  );
}

// Interface
NavbarDropdownItem.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
NavbarDropdownItem.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default NavbarDropdownItem;

