export const onLogin = (response) => {
  try {
    console.log("google.onLogin");
    console.log(JSON.stringify(response));
    if (response && response.tokenObj && response.tokenObj.id_token) {
      const token = response.tokenObj.id_token;
      window.localStorage.setItem("token", token);
    }
    if (response && response.googleId && (response.googleId.length > 0)) {
      const username = response.googleId;
      window.document.cookie = `a2a_user=${this.username}; path=/; max-age=63072000`; // 2 years
    }
  } catch (error) {
    console.error(`Error: ${error}`);
  }
}
  
export const onLogout = () => {
  try {
    console.log("google.onLogout");
    window.localStorage.removeItem("token");
  } catch (error) {
    console.error(`Error: ${error}`);
  }
}

export const isLoggedIn = () => {
  if (window.localStorage.getItem("token")) {
    return true;
  } else {
    return false;
  }
}

function parseJwt(token) {
  let base64Url = token.split(".")[1];
  let base64 = base64Url.replace("-", "+").replace("_", "/");
  return JSON.parse(window.atob(base64));
}

