///////////////////////////////////////////////////////////
// File        : Logo.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Logo.scss";

// Function Definition
function Logo(props) {
  // console.info("Logo.render");
  let logoElement =
    (() => { // Uses dynamic creation
      try {
        const ViewClass = require("~/LocalComponents/Items/Logo/Logo").default; // Dynamically (build-time) import the appropriate class
        const ViewName = React.createFactory(ViewClass); // Then create a factory method for it
        return (<ViewName/>); // React.createElement will do the rest
      } catch (error) {
        console.error(`React.createFactory: ${error}`);
        return null;
      }
    })();
  return (
    <>
      {logoElement}
    </>
  );
}

// Interface
Logo.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Logo.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Logo;

