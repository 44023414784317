///////////////////////////////////////////////////////////
// File        : Logo.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Logo.scss";

// Function Definition
function Logo(props) {
  // console.info("Logo.render");
  return (
    <svg className="logo" viewBox="0 0 227 150">
      <g>
        <g>
          <path
            className="topA"
            d="M 55.93,127.61 C 62.08,119.24 112.06,51.52 112.76,50.57 113.50,51.52 163.47,119.24 169.62,127.61 169.62,127.61 219.06,145.25 219.06,145.25 184.41,96.79 149.61,48.50 112.76,1.29 75.95,48.53 41.14,96.79 6.47,145.25 6.47,145.25 55.93,127.61 55.93,127.61 Z"
            />
        </g>
        <g>
          <path
            className="bottomA"
            d="M 112.76,109.43 C 113.27,109.27 148.91,121.44 155.06,123.57 155.12,123.54 155.19,123.50 155.25,123.44 141.29,104.88 126.82,85.65 112.76,66.96 98.36,85.72 84.59,104.91 70.28,123.47 70.82,123.50 99.02,114.05 112.76,109.43 Z"
            />
        </g>
      </g>
    </svg>
  );
}

// Interface
Logo.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
Logo.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default Logo;

