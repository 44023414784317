///////////////////////////////////////////////////////////
// File        : NavbarDropdown.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./NavbarDropdown.scss";
import NavbarDropdownItem from "~/Components/Items/NavbarDropdownItem/NavbarDropdownItem";

// Function Definition
function NavbarDropdown(props) {
  // console.info("NavbarDropdown.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id} = props;
  const [label, setLabel] = useState("");
  const [items, setItems] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.label && setLabel(fields.label);
            if (fields.items && Array.isArray(fields.items)) {
              setItems(fields.items.map((item, index) => (
                <NavbarDropdownItem key={item.sys.id} id={item.sys.id} />
              )));
            }
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <li className="nav-item dropdown mr-lg-4 navbarDropdown">
      <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {label}
      </a>
      <div className="dropdown-menu dropdown-menu-right rounded shadow menu-animate slideIn" aria-labelledby="navbarDropdown">
        {items}
      </div>
    </li>
  );
}

// Interface
NavbarDropdown.propTypes = Object.assign({}, defaultComponentPropTypes, {
  // <value>: PropTypes.string,
});
NavbarDropdown.defaultProps = Object.assign({}, defaultComponentProps, {
  // <value>: "",
});

// Exports

export default NavbarDropdown;

