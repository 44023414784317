///////////////////////////////////////////////////////////
// File        : Link.jsx
// Description : 

// Imports : 

import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {  } from "react-bootstrap";
// import jsonpath from "jsonpath";

import { DataContext, useIsMounted } from "~/Context";
import defaultComponentPropTypes from "~/Components/api/ComponentPropTypes";
import defaultComponentProps from "~/Components/api/ComponentProps";
import "./Link.scss";

// Function Definition
function Link(props) {
  // console.info("Link.render");
  const dataContext = useContext(DataContext);
  const isMounted = useIsMounted();
  const {id, className, children} = props;
  const [link, setLink] = useState("");
  const [label, setLabel] = useState("");
  const queryEndpoint = dataContext.retrieveEndpoint(id);
  useEffect(() => {
    try {
      dataContext.Get(queryEndpoint)
        .then((response) => {
          const fields = response.data.fields; // jsonpath.value(response, "$.data.fields"); // Get the children for this component
          // console.info(JSON.stringify(fields, null, 2));
          if (fields && isMounted.current) {
            fields.link && setLink(fields.link);
            fields.label && setLabel(fields.label);
          }
        })
        .catch((error) => console.error(`[API] ${error} [${id}]`))
        ;
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  }, []);
  return (
    <a className={`${className} link`} href={link}>{children ? children : label}</a>
  );
}

// Interface
Link.propTypes = Object.assign({}, defaultComponentPropTypes, {
  className: PropTypes.string,
  children: PropTypes.object,
});
Link.defaultProps = Object.assign({}, defaultComponentProps, {
  className: "",
  children: null,
});

// Exports

export default Link;

